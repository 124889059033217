import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { rateLimitCheckRestart } from "../actions/auth";



export const TimerRateLimit = () => {
    const [timerTime, setTimerTime] = useState('');
    const dispatch = useDispatch();

    const TIME_LIMIT = 30; //* el tiempo es ficticio y se reinicia cada vez que la pagina se recarga, o se activa la funcion, grabar el tiempo en el localStorage
    let timePassed = 0;
    let timeLeft = TIME_LIMIT;
    let timerInterval = null;

    useEffect(() => {

        startTimer();
        //eslint-disable-next-line
    }, [])

    const onTimesUp = () => {
        clearInterval(timerInterval);
        dispatch(rateLimitCheckRestart());
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        let seconds = time % 60;

        if (seconds < 10) {
            seconds = `0${seconds}`;
        }

        return `${minutes}:${seconds}`;
    }

    const startTimer = () => {
        timerInterval = setInterval(() => {
            timePassed = timePassed += 1;
            timeLeft = TIME_LIMIT - timePassed;

            setTimerTime(formatTime(timeLeft));
            

            if (timeLeft === 0) {
                onTimesUp();
            }
        }, 1000);
    }

    return (
        <>
        <div style={{margin: 'auto', textAlign: 'center'}}>
            <p style={{margin: 'auto', fontSize: '2rem', fontWeight: 'bold'}}>Tiempo para hacer otra peticion</p>
            <p style={{margin: 'auto', fontSize: '2rem', fontWeight: 'bold'}}>{timerTime}</p>
        </div>
        </>
    )
}

