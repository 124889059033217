import { types } from "../types/types";

const initialState = {
    checking: true,
    captchaToken: null,
    rateLimit: false
}

export const authReducer = (state = initialState, action) => {
    
    switch (action.type) {

        case types.authRecaptcha:
            return {
                ...state,
                captchaToken: action.payload,
                // checking: true
            }
        case types.authRecaptchaReset:
            return {
                ...state,
                captchaToken: null,
            }
        case types.authLogin:
            return {
                ...state,
                ...action.payload,
                checking: false
            }

        case types.authCheckingFinish:
            return {
                ...state,
                checking: false
            }
        case types.authChecking:
            return {
                ...state,
                checking: true
            }
        case types.authRateLimit:
            return {
                ...state,
                rateLimit: true
            }
        case types.authRateLimitRestart:
            return {
                ...state,
                rateLimit: false
            }
            
        default:
            return state;
    }
}