import cryptojs from 'crypto-js'

const config = {
  secrets: {
    encryptPassphrase:
      process.env.REACT_APP_ENCRYPT_PASSPHRASE || 'myencryptpassphrase',
    decryptPassphrase: process.env.REACT_APP_DECRYPT_PASSPHRASE || 'mydecryptpassphrase',
  },
};

const encrypt = (text) => {
    const cipherToken = cryptojs.AES.encrypt(
      text,
      config.secrets.encryptPassphrase
    ).toString();

    return cipherToken;
}

const decrypt = (text) => {
  const bytes = cryptojs.AES.decrypt(text, config.secrets.decryptPassphrase);

  const descipherToken = bytes.toString(cryptojs.enc.Utf8);

  return descipherToken;
};

const encryptObject = (object) => {
    const cipherObj = cryptojs.AES.encrypt(JSON.stringify(object), config.secrets.encryptPassphrase).toString()

    return cipherObj
}

const decryptObject = (object) => {
    const bytes = cryptojs.AES.decrypt(object, config.secrets.decryptPassphrase)

    const decryptedObj = JSON.parse(bytes.toString(cryptojs.enc.Utf8))

    return decryptedObj
}

export { encryptObject, decryptObject, encrypt, decrypt }
