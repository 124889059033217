import React, { useEffect, useMemo, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { isTokenStillValid, parseJWT } from '../helpers/validateToken';
import '../styles/Timer.css';

export const Timer = () => {

    const [showAlert, setShowAlert] = useState(null);
    const [timerTime, setTimerTime] = useState('');
    const [attributePath, setAttributePath] = useState('');

    const pathColor = useRef();

    let token = localStorage.getItem('token');
    let secondsToken;
    let timeCreate ;
    let totalSeconds;
    let totalTimeToken;

    if (token) {
        secondsToken = parseJWT(token).exp;
        timeCreate = parseJWT(token).iat;
        totalSeconds = (secondsToken - Math.floor(Date.now() / 1000));
        totalTimeToken = (secondsToken - timeCreate);
    } 
    
    // Credit: Mateusz Rybczonec

    const FULL_DASH_ARRAY = 283;
    const WARNING_THRESHOLD = Math.floor(totalTimeToken * 25) / 100; // 25%
    const ALERT_THRESHOLD = Math.floor(totalTimeToken * 15) / 100; // 15%
    
    const COLOR_CODES =
    useMemo(() => 
    ( {
            info: {
                color: "green"
            },
            warning: {
                color: "orange",
                threshold: WARNING_THRESHOLD
            },
            alert: {
                color: "red",
                threshold: ALERT_THRESHOLD
            }
        })
    , [ALERT_THRESHOLD, WARNING_THRESHOLD]) 

    const TIME_LIMIT = totalSeconds;
    let timePassed = 0;
    let timeLeft = TIME_LIMIT;
    let timerInterval = null;
    let remainingPathColor = COLOR_CODES.info.color;
    

    useEffect(() => {

        startTimer();
        //eslint-disable-next-line
    }, [])


    const onTimesUp = () => {
        clearInterval(timerInterval);
        Swal.fire({
            title: 'Tiempo de sesión expirado',
            text: "Por favor recargue la pagina.",
            icon: 'warning',
            iconColor: '#F79327',
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonColor: '#F79327',
            confirmButtonText: 'Recargar la pagina',
            }).then((result) => {
            if (result.isConfirmed) {
                window.location.reload();
            }
        })
    }
    


    const startTimer = () => {
        timerInterval = setInterval(() => {
            timePassed = timePassed += 1;
            timeLeft = TIME_LIMIT - timePassed;
    
            setTimerTime(formatTime(timeLeft));
            
            setCircleDasharray();

            if (timeLeft <= 0) {
                onTimesUp();
            }
        }, 1000);
    }

      
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        let seconds = time % 60;

        if (seconds < 10) {
            seconds = `0${seconds}`;
        }

        if (time < 0) {
            return `00:00`;
        }

        return `${minutes}:${seconds}`;
    }

   
    useEffect(() => {
        const { alert, warning, info } = COLOR_CODES;
        if (timeLeft <= alert.threshold) {
            pathColor.current.classList.remove(warning.color);
            pathColor.current.classList.add(alert.color);
        } else if (timeLeft <= warning.threshold) {
            pathColor.current.classList.remove(info.color);
            pathColor.current.classList.add(warning.color);
        }
    }, [timeLeft, COLOR_CODES])
        
    
    const calculateTimeFraction = () => {
        const rawTimeFraction = timeLeft / totalTimeToken;
        let temp = rawTimeFraction - (1 / totalTimeToken) * (1 - rawTimeFraction);
        
        return temp;
    }

    const setCircleDasharray = () => {
       
        setAttributePath(`${(
                calculateTimeFraction() * FULL_DASH_ARRAY
            ).toFixed(0)} 283`);

    }
 
    useEffect(() => {
        setTimeout(() => {
            if ( isTokenStillValid(parseJWT(localStorage.getItem('token')))) {
                setShowAlert(true);
            } else {
                setShowAlert(false);
            }
        }, 1500);
    
      
        setTimeout(() => {
            setShowAlert(false);
        }, 4500);
      
    }, [])

  return (
      <>
        {showAlert 
        ?  <div className="alert">
                <h4 className='me-5 text-alert'>Tiempo para canjear la tarjeta</h4>
            </div>
        : null}
        <div id="timerT">
        <div className="base-timer">
            <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <g className="base-timer__circle">
                <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                <path
                    id="base-timer-path-remaining"
                    strokeDasharray={attributePath}
                    ref={pathColor}
                    className={`base-timer__path-remaining ${remainingPathColor}`}
                    d="
                    M 50, 50
                    m -45, 0
                    a 45,45 0 1,0 90,0
                    a 45,45 0 1,0 -90,0
                    "
                ></path>
                </g>
            </svg>
            <span id="base-timer-label" className="base-timer__label" >
                {timerTime}
            </span>
            </div>
            
        </div>
      </>
  )
}
