const parseJWT = (jwtString) => JSON.parse(atob(jwtString.split('.')[1]));

const isTokenStillValid = (parsedToken) => {
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime < parsedToken.exp;
};

export {
    parseJWT,
    isTokenStillValid
}