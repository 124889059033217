export const types = {


    authCheckingFinish: '[Auth] AuthCheckingFinish',
    authLogin: '[Auth] AuthLogin',
    authRecaptcha: '[Auth] Recaptcha',
    authRecaptchaReset: '[Auth] RecaptchaReset',
    authChecking: '[Auth] AuthChecking',
    authRateLimit: '[Auth] AuthRateLimit',
    authRateLimitRestart: '[Auth] AuthRateLimitRestart',


    
    /*Estados para las tarjetas*/

    tarjetaGetTarjeta: '[Tarjeta] GetTarjeta',
    tarjetaValuesTarjeta: '[Tarjeta] ValuesTarjeta',
    tarjetaFoundTarjeta: '[Tarjeta] FoundTarjeta',
    tarjetaFoundTarjetaTrue: '[Tarjeta] FoundTarjetaTrue',
    tarjetaRedeemTarjeta: '[Tarjeta] RedeemTarjeta',
    tarjetaClearTarjeta: '[Tarjeta] ClearTarjeta',

}