import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Col, Container, ProgressBar, Row, Form } from 'react-bootstrap';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { checkingCaptchaFinish } from '../actions/auth';
import { addTarjetaInfo, getTarjetaValues, redeemGiftcard, startGetTarjetaValues } from '../actions/tarjetas';
import { isTokenStillValid, parseJWT } from '../helpers/validateToken';
import { useForm } from '../hooks/useForm';
import { encrypt, decryptObject } from '../helpers/crypto';
import Swal from 'sweetalert2';
import QRCode from "react-qr-code";
import '../styles/Tabs.css';
import logoDitobanxBlack from '../assets/LogoDitobanxBlack.svg';
import checkedIcon from '../assets/checkedIcon.svg'
import { changeFoundTarjeta, clearTarjetaInfo } from '../actions/tarjetas';
import { getFetch, postFetch } from '../helpers/fetch';
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import celocircle from '../assets/celo/celo-glyph.png'
import celoIcon from '../assets/celo/celo-icon.svg'
import celousd from '../assets/celo/cUSD-icon.svg'


export const ConfirmarTarjeta = ( {captcha} ) => {

  const [refCallback,] = useKeenSlider(
    {
      slideChanged() {
      },
        loop: true,
        slides:
        {
          perView: 1,
          number: 3,
          spacing: 0,
        }
    },
    [
      // add plugins here
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
            clearTimeout(timeout)
        }
        function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
                slider.next()
            }, 2000)
        }
        slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
                mouseOver = true
                clearNextTimeout()
            })
            slider.container.addEventListener("mouseout", () => {
                mouseOver = false
                nextTimeout()
            })
            nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
    },
    ]
  )
  // const ENDPOINT = process.env.REACT_APP_SOCKET_URL;

  const [screen, setScreen] = useState({matches: window.matchMedia("(min-width: 768px)").matches});
  const [priceBTC, setPriceBTC] = useState();
  const [equivalentBTC, setEquivalentBTC] = useState();
  // const [networkCost, setNetworkCost] = useState();
  const [commission, setCommission] = useState();
  const [totalBTC, setTotalBTC] = useState();
  const [view, setView] = useState('lightning');
  const [response, setResponse] = useState("");
  const [fees, setFees] = useState('')
  const [showEmail, setShowEmail] = useState("");
  const [showAddress, setShowAddress] = useState('')
  const [progress, setProgress] = useState(100);
  const intervalId = useRef()
  const intervalRedeem = useRef()

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore();

  const { amount, code, qrCode, publicKey, transactionId } = useSelector(state => state.tarjetas);
  const encryptedKey = encrypt(process.env.REACT_APP_API_KEY);
  const validEmail = new RegExp(
    '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
  );
  
  const { ip } = useSelector(state => state.auth);
  let token = localStorage.getItem('token');

  const getBTCvalue = async () => {
    const url = process.env.REACT_APP_API_URL + '/giftcard/celo/current';
    const token = localStorage.getItem('token') || '';
    const encryptedToken = encrypt(token);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        key: encryptedKey,
        Authorization: `Bearer ${encryptedToken}`,
      },
    };
    const requestData = {
      amount: amount
    }
    const res = await axios.post(url, requestData, config)

    res.data.data = decryptObject(res.data.data)

    setPriceBTC(res.data.data.amount);
    setEquivalentBTC(res.data.data.equivalent);
    setCommission(res.data.data.percent);
    setTotalBTC(res.data.data.totalAmount);
  }

  const verifyRedeem = async () => {
    const resp = await postFetch('giftcard/verify/redeem', {
      publicKey,
      code,
    })

     return resp
  }

  const fetchFees = async () => {
    const response = await getFetch('giftcard/btc/fees')
    setFees(response.data)
  }

  const sendEmail = async (email, email2, email3, transactionId) => {
    const url = process.env.REACT_APP_API_URL + '/giftcard/receip/send';
    const token = localStorage.getItem('token') || '';
    const encryptedToken = encrypt(token);

    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        key: encryptedKey,
        Authorization: `Bearer ${encryptedToken}`,
      },
    };
    const requestData = {
      email,
      email2,
      email3,
      publicKey,
      code,
      type: 'ln',
    }

    const res = await axios.post(url, requestData, config)
    res.data.data = decryptObject(res.data.data);
    if (res.data.status && res.data.key === 'receip:sent') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Email enviado',
        iconColor: '#F7931A',
        text: 'Te hemos enviado un email con los datos de la transacción',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#F7931A',
      })
      setShowEmail("NO");
    }
  }

  useEffect(() => {
    getBTCvalue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchFees()
  }, [])

  useEffect(() => {
    
    startTimer();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (response === "OK") {
      intervalId.current = null
      intervalRedeem.current = null
    }
  
    return () => {
      clearInterval(intervalId.current)
      clearInterval(intervalRedeem.current)
    }
  }, [intervalId, response, intervalRedeem])

  useEffect(() => {
    const timeProgress = setInterval(() => {
      if (progress > 0) {
        setProgress(progress - (100 / 30));
      }
    }, 1000);

    if (isTokenStillValid(parseJWT(localStorage.getItem('token'))) === false ||  response === "OK") {
      clearInterval(timeProgress);
    }

    return () => {
      clearInterval(timeProgress);
    }
  
  }, [progress, response])

  const startTimer = () => {

    setInterval(() => {
      if ( isTokenStillValid(parseJWT(localStorage.getItem('token'))) === false ) {
        clearInterval(intervalId);
      }
    }, 1000);
    
    // setIntervalId(
      intervalId.current = setInterval(() => {
        if ( isTokenStillValid(parseJWT(localStorage.getItem('token')))) {
          dispatch(startGetTarjetaValues(publicKey, code))
          getBTCvalue();
          // verify
          setProgress(100);
        } else {
          clearInterval(intervalId.current);
          return;
        }
      }, 31000)
    // )

    // setIntervalRedeem(
      intervalRedeem.current = setInterval(async () => {
        if ( isTokenStillValid(parseJWT(localStorage.getItem('token')))) {
          const result = await verifyRedeem()
          fetchFees()

          if (result.data) {
            setResponse('OK');
            setShowEmail('OK');
            setShowAddress(result.data.wallet)
          }
        } else {
          clearInterval(intervalRedeem.current);
          return;
        }
      }, 5000)
    // )
  }

  const handlerScreen = e => setScreen({matches: e.matches});
  window.matchMedia("(min-width: 768px)").addEventListener('change', handlerScreen);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const initialForm = {
    email: null,
    email2: null,
    email3: null,
    wallet: '',
    walletCUSD: '',
    fee: 0,
  }

  const [values, handleInputChange, reset] = useForm(initialForm);

  const { email, email2, email3, wallet, walletCUSD, fee } = values

  useEffect(() => {
    dispatch(addTarjetaInfo({
        priceBTC,
        equivalentBTC,
        commission,
        totalBTC,
    }))
  }, [priceBTC, equivalentBTC, commission, totalBTC, dispatch])
  

  if (!captcha) {
    return <Navigate to="/canjear" replace />;
  }

   const validWallet = new RegExp('^0x[a-fA-F0-9]{40}$')

   const isUrl = new RegExp(
    // eslint-disable-next-line no-useless-escape
    '^(http[s]?:\/\/)?([^\/\s]+\/)(.*)$'
   )
  
  const handleSubmitCUSD = async (e) => {
    e.preventDefault();
    if (view === 'lightning') {
      const reqFee = fee === 0 ? fees.fastestFee : Number(fee)
      if (walletCUSD === '') {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Campo obligatorio',
          iconColor: '#F79327',
          text: 'Por favor completa el campo con tu wallet',
          showConfirmButton: false,
          timer: 4500,
        })
      } else if (!validWallet.test(walletCUSD)) {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'wallet invalido',
          iconColor: '#F79327',
          text: 'Por favor ingresa una wallet valida',
          showConfirmButton: false,
          timer: 4500,
        })
      } else if (isUrl.test(walletCUSD)) {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'wallet invalido',
          iconColor: '#F79327',
          text: 'Por favor ingresa una wallet valida',
          showConfirmButton: false,
          timer: 4500,
        })
      } else {
        if (token && isTokenStillValid(parseJWT(token))) {
          clearInterval(intervalId.current)
          clearInterval(intervalRedeem.current)
          await dispatch(
            redeemGiftcard(
              code,
              publicKey,
              walletCUSD,
              navigator.userAgent,
              ip,
              email,
              amount,
              reqFee,
              'CUSD'
            )
          )
          store.getState().tarjetas.walletFound
            ? navigate('/realizado', { replace: true })
            : console.log('error')
          dispatch(checkingCaptchaFinish())
        } else if (!token) {
          Swal.fire({
            title: 'Ha ocurrido un error',
            text: 'Por favor recargue la pagina.',
            icon: 'warning',
            iconColor: '#F79327',
            showCancelButton: false,
            confirmButtonColor: '#F79327',
            confirmButtonText: 'Recargar la pagina',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        } else if (!isTokenStillValid(parseJWT(token))) {
          Swal.fire({
            title: 'Tiempo de sesión expirado',
            text: 'Por favor recargue la pagina.',
            icon: 'warning',
            iconColor: '#F79327',
            showCancelButton: false,
            confirmButtonColor: '#F79327',
            confirmButtonText: 'Recargar la pagina',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        }

        reset()
      }
    } else {
      return
    }
  }

  const handleSubmitCELO = async (e) => {
    e.preventDefault()
    if (view === 'onchain') {
      const reqFee = fee === 0 ? fees.fastestFee : Number(fee)
      if (wallet === '') {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Campo obligatorio',
          iconColor: '#F79327',
          text: 'Por favor completa el campo con tu wallet',
          showConfirmButton: false,
          timer: 4500,
        })
      } else if (!validWallet.test(wallet)) {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'wallet invalido',
          iconColor: '#F79327',
          text: 'Por favor ingresa una wallet valida',
          showConfirmButton: false,
          timer: 4500,
        })
      } else if (isUrl.test(wallet)) {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'wallet invalido',
          iconColor: '#F79327',
          text: 'Por favor ingresa una wallet valida',
          showConfirmButton: false,
          timer: 4500,
        })
      } else {
        if (token && isTokenStillValid(parseJWT(token))) {
          clearInterval(intervalId.current)
          clearInterval(intervalRedeem.current)
          await dispatch(
            redeemGiftcard(
              code,
              publicKey,
              wallet,
              navigator.userAgent,
              ip,
              email,
              amount,
              reqFee,
              'CELO'
            )
          )
          store.getState().tarjetas.walletFound
            ? navigate('/realizado', { replace: true })
            : console.log('error')
          dispatch(checkingCaptchaFinish())
        } else if (!token) {
          Swal.fire({
            title: 'Ha ocurrido un error',
            text: 'Por favor recargue la pagina.',
            icon: 'warning',
            iconColor: '#F79327',
            showCancelButton: false,
            confirmButtonColor: '#F79327',
            confirmButtonText: 'Recargar la pagina',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        } else if (!isTokenStillValid(parseJWT(token))) {
          Swal.fire({
            title: 'Tiempo de sesión expirado',
            text: 'Por favor recargue la pagina.',
            icon: 'warning',
            iconColor: '#F79327',
            showCancelButton: false,
            confirmButtonColor: '#F79327',
            confirmButtonText: 'Recargar la pagina',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          })
        }

        reset()
      }
    } else {
      return
    }
  }


  let buttons = document.querySelectorAll(".selected");

  buttons.forEach(button =>{
      button.addEventListener("click",_ =>{
        buttons.forEach(button =>{
          button.classList.remove("edit");
          
        })
        button.classList.toggle("edit");
      })
    })

    // const submitEmail = (e) => {
    //   e.preventDefault();
    //   if (!validEmail.test(email)) {
    //     Swal.fire({
    //       position: 'center',
    //       icon: 'warning',
    //       title: 'Email invalido',
    //       iconColor: '#F79327',
    //       text: 'Por favor ingresa un email valido',
    //       showConfirmButton: false,
    //       timer: 4500
    //     })
    //     return;
    //   }
    //   sendEmail(email, email2, email3, transactionId);

    //   reset();
    // }

    const handlerOtherGiftCard = () => {
      dispatch(changeFoundTarjeta());
      dispatch(clearTarjetaInfo());
      navigate('/canjear');
    }
  
    const goHome = () => {
      dispatch(changeFoundTarjeta());
      dispatch(clearTarjetaInfo());
      navigate('/home');
    }

  return (
    <Container>
      <form>
        <Row>
          <Col xs={0} sm={0} md={1} lg={2}></Col>
          <Col xs={12} sm={12} md={10} lg={8} style={{ textAlign: 'center' }}>
            <div className="title-separator mt-5"></div>

            <div className="container-tabs">
              <button
                className="selected edit"
                onClick={(e) => {
                  e.preventDefault()
                  setView('lightning')
                }}
                id="lightning"
              >
                <img src={celousd} alt="celo-icon" className="cusd-icon"></img>
                CUSD
              </button>
              {response === 'OK' ? null : (
                <button
                  className="selected"
                  onClick={(e) => {
                    e.preventDefault()
                    setView('onchain')
                  }}
                  id="onchain"
                >
                  <img
                    src={celoIcon}
                    alt="celo-icon"
                    className="celo-icon"
                  ></img>
                  CELO
                </button>
              )}
            </div>

            {view === 'onchain' ? (
              <div
                className="card-transaction card-shadow"
                style={{
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '0px',
                }}
              >
                <Container>
                  <Row>
                    <Col>
                      <img
                        className="img-card-transaction img-fluid"
                        src={logoDitobanxBlack}
                        alt="tarjeta"
                      />
                    </Col>
                  </Row>
                  {store.getState().tarjetas.walletFound ? (
                    <>
                      <Row>
                        <Col>
                          <img
                            className="img-card-transaction img-fluid"
                            src={checkedIcon}
                            alt="checked"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h1 className="bold mb-3">
                            Transacción realizada onchain
                          </h1>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  <Row>
                    <Col xs={0} md={2}></Col>
                    <Col xs={12} md={8} style={{ textAlign: 'center' }}>
                      <h1 className="bold mt-5">Valor de la tarjeta</h1>
                    </Col>
                    <Col xs={0} md={2}></Col>
                  </Row>
                  <Row>
                    <Col xs={0} md={2}></Col>
                    <Col xs={12} md={8} style={{ textAlign: 'center' }}>
                      <h1 className="bold mb-5">
                        $ {amount % 1 === 0 ? amount + '.00' : amount}
                      </h1>
                    </Col>
                    <Col xs={0} md={2}></Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="progressBar mt-3">
                        <ProgressBar now={progress} variant="dark" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="bold mt-3 card-text-left">Precio Celo</p>
                    </Col>
                    <Col>
                      <p className="bold mt-3 card-text-right">${priceBTC}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="linea-separadora-dark"></Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="bold mt-3 card-text-left">
                        Equivalente en Celo
                      </p>
                    </Col>
                    <Col>
                      <p className="bold mt-3 card-text-right">
                        {Number.parseFloat(equivalentBTC).toFixed(8)}
                      </p>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="linea-separadora-dark"></Col>
                  </Row> */}
                  {/* <Row>
                    <Col>
                      <p className="bold mt-3 card-text-left" style={{ textAlign: 'left' }}>
                        Costo de red
                      </p>
                    </Col>
                    <Col>
                      <p className="bold mt-3 card-text-right" style={{ textAlign: 'right' }}>
                        ($1.00) {networkCost}
                      </p>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col className="linea-separadora-dark"></Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="bold mt-3 card-text-left">
                        Comisión de servicio Celo
                      </p>
                    </Col>
                    <Col>
                      <p className="bold mt-3 card-text-right">
                        5% ({Number.parseFloat(commission).toFixed(8)})
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="linea-separadora-dark"></Col>
                  </Row>
                  <Row style={{ backgroundColor: '#ffffff' }}>
                    <Col>
                      <p className="bold mt-3 card-text-left">Celo a recibir</p>
                    </Col>
                    <Col>
                      <p className="bold mt-3 card-text-right">
                        {Number.parseFloat(totalBTC).toFixed(8)}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="linea-separadora-dark"></Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5
                        style={{ textAlign: 'center' }}
                        className="bold mt-3 card-text-wallet-onchain"
                      >
                        Pega la dirección Celo de tu wallet en el siguiente
                        espacio
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3 mb-5">
                      <input
                        type="text"
                        name="wallet"
                        value={wallet}
                        onChange={handleInputChange}
                        className="field-wallet"
                        placeholder="0xb15b3b098c9aefd64edb8339e5283068195558e2"
                      />
                    </Col>
                  </Row>
                </Container>
                <Row>
                  <Col xs={0} md={0}></Col>
                  <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                    <button
                      style={{ width: '8rem' }}
                      className="btn-canjear"
                      onClick={handleSubmitCELO}
                    >
                      Canjear
                    </button>
                  </Col>
                  <Col xs={0} md={0}></Col>
                </Row>
              </div>
            ) : (
              <div
                className="card-transaction card-shadow"
                style={{
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '0px',
                }}
              >
                <Container>
                  <Row>
                    <Col>
                      <img
                        className="img-card-transaction img-fluid"
                        src={logoDitobanxBlack}
                        alt="tarjeta"
                      />
                    </Col>
                  </Row>
                  {store.getState().tarjetas.walletFound ? (
                    <>
                      <Row>
                        <Col>
                          <img
                            className="img-card-transaction img-fluid"
                            src={checkedIcon}
                            alt="checked"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h1 className="bold mb-3">
                            Transacción realizada onchain
                          </h1>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  <Row>
                    <Col xs={0} md={2}></Col>
                    <Col xs={12} md={8} style={{ textAlign: 'center' }}>
                      <h1 className="bold mt-5">Valor de la tarjeta</h1>
                    </Col>
                    <Col xs={0} md={2}></Col>
                  </Row>
                  <Row>
                    <Col xs={0} md={2}></Col>
                    <Col xs={12} md={8} style={{ textAlign: 'center' }}>
                      <h1 className="bold mb-5">
                        $ {amount % 1 === 0 ? amount + '.00' : amount}
                      </h1>
                    </Col>
                    <Col xs={0} md={2}></Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="progressBar mt-3">
                        <ProgressBar now={progress} variant="dark" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="bold mt-3 card-text-left">Valor 1 CUSD</p>
                    </Col>
                    <Col>
                      <p className="bold mt-3 card-text-right">1 CELO</p>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="linea-separadora-dark"></Col>
                  </Row> */}
                  {/* <Row>
                    <Col>
                      <p className="bold mt-3 card-text-left" style={{ textAlign: 'left' }}>
                        Costo de red
                      </p>
                    </Col>
                    <Col>
                      <p className="bold mt-3 card-text-right" style={{ textAlign: 'right' }}>
                        ($1.00) {networkCost}
                      </p>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col className="linea-separadora-dark"></Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="bold mt-3 card-text-left">
                        Comisión de servicio CUSD
                      </p>
                    </Col>
                    <Col>
                      <p className="bold mt-3 card-text-right">
                        5% ({Number.parseFloat(amount * 0.05).toFixed(8)})
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="linea-separadora-dark"></Col>
                  </Row>
                  <Row style={{ backgroundColor: '#ffffff' }}>
                    <Col>
                      <p className="bold mt-3 card-text-left">CUSD a recibir</p>
                    </Col>
                    <Col>
                      <p className="bold mt-3 card-text-right">
                        {Number.parseFloat(amount - (amount * 0.05)).toFixed(8)}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="linea-separadora-dark"></Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5
                        style={{ textAlign: 'center' }}
                        className="bold mt-3 card-text-wallet-onchain"
                      >
                        Pega la dirección CUSD de tu wallet en el siguiente
                        espacio
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3 mb-5">
                      <input
                        type="text"
                        name="walletCUSD"
                        value={walletCUSD}
                        onChange={handleInputChange}
                        className="field-wallet"
                        placeholder="0xb15b3b098c9aefd64edb8339e5283068195558e2"
                      />
                    </Col>
                  </Row>
                </Container>
                <Row>
                  <Col xs={0} md={0}></Col>
                  <Col xs={12} md={12} style={{ textAlign: 'center' }}>
                    <button
                      style={{ width: '8rem' }}
                      className="btn-canjear"
                      onClick={handleSubmitCUSD}
                    >
                      Canjear
                    </button>
                  </Col>
                  <Col xs={0} md={0}></Col>
                </Row>
              </div>
            )}

            {response === 'OK' ? (
              <>
                <div className="d-none d-md-block d-lg-block">
                  <Row>
                    <Col xs={0} md={2}></Col>
                    <Col xs={6} md={4} style={{ textAlign: 'center' }}>
                      <button
                        className="btn-alternative btn-realizado-canjear"
                        onClick={handlerOtherGiftCard}
                      >
                        Canjear otra
                      </button>
                    </Col>
                    <Col xs={6} md={4} style={{ textAlign: 'center' }}>
                      <button
                        className="btn-canjear btn-realizado-finalizar"
                        onClick={goHome}
                      >
                        Finalizar
                      </button>
                    </Col>
                    <Col xs={0} md={2}></Col>
                  </Row>
                </div>

                <div className="d-block d-md-none d-lg-none d-xl-none">
                  <Row>
                    <Col xs={6} sm={6} style={{ textAlign: 'center' }}>
                      <button
                        className="btn-alternative"
                        onClick={handlerOtherGiftCard}
                      >
                        Canjear otra
                      </button>
                    </Col>
                    <Col xs={6} sm={6} style={{ textAlign: 'center' }}>
                      <button className="btn-canjear" onClick={goHome}>
                        Finalizar
                      </button>
                    </Col>
                  </Row>
                </div>
              </>
            ) : null}
          </Col>

          {screen.matches ? (
            <Col
              xs={0}
              sm={0}
              md={1}
              lg={2}
              style={{
                textAlign: 'left',
                marginLeft: '-18rem',
                marginTop: '20rem',
                zIndex: -10,
              }}
            >
              <img
                className="img-bitcoin-transaccion"
                src={celocircle}
                alt="tarjeta"
              />
            </Col>
          ) : (
            <Col xs={0} sm={0} md={1} lg={2}></Col>
          )}
        </Row>
      </form>
      <Row>
        <Col xs={0} sm={0} md={1} lg={2}></Col>
        <Col xs={12} sm={12} md={10} lg={8}>
          <div className="acordion-card">
            <h4 className="bold mt-5 mb-3" style={{ textAlign: 'center' }}>
              ¿Necesitas ayuda?
            </h4>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  No puedo canjear el código de mi tarjeta de regalo
                </Accordion.Header>
                <Accordion.Body>
                  <p style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                    Si durante el proceso de canje de tu tarjeta presentas algún
                    problema, asegúrate de estar conectado a internet y que
                    poseas navegación (puedes realizar la prueba abriendo una
                    pestaña adicional y cargando alguna página que no sea&nbsp;
                    <a
                      href="https://google.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      google.com
                    </a>
                    ).
                  </p>
                  <p style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                    Si el problema aún persiste y para que podamos brindarte un
                    mejor servicio, contáctanos a nuestro correo&nbsp;
                    <a href="mailto:soporte@ditobanx.com">
                      soporte@ditobanx.com
                    </a>
                    &nbsp;y compártenos la siguiente información:
                  </p>
                  <ol>
                    <li style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                      Capturas de pantalla (de todo el navegador donde se
                      visualice la barra URL) del mensaje de error y códigos que
                      está utilizando.
                    </li>
                    <li style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                      Captura de ambos lados de la giftcard que está utilizando,
                      donde los códigos traseros sean completamente visibles.
                    </li>
                    <li style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                      Descripción de las condiciones en las cuales ocurre el
                      error para que nuestro equipo pueda replicarlo.
                    </li>
                    <li style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                      Información de a donde podamos contactárte: email y número
                      de teléfono, en caso de ser necesario el contacto por este
                      medio.
                    </li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  ¿Cómo puedo redimir mi giftcard?
                </Accordion.Header>
                <Accordion.Body>
                  <p style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                    En la parte trasera encontrarás dos códigos rascables que
                    estarán identificados y que podrás ingresar en el apartado
                    que nuestro sitio web te indique. Deberás seleccionar el
                    método que deseas utilizar para recibir tu bitcoin, ya sea
                    vía lightning u onchain (asegúrate de que tu billetera sea
                    compatible con estos métodos).
                  </p>
                  <p style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                    Al momento de realizar el canje, aparecerá una ventana
                    confirmando la transacción que te dará la opción de enviar
                    tu comprobante al correo que desees.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Me mostró la ventana de confirmación y aun no veo reflejado en
                  mi wallet el monto de la giftcard
                </Accordion.Header>
                <Accordion.Body>
                  <p style={{ textAlign: 'justify', fontSize: '0.9rem' }}>
                    No te preocupes, es posible que la blockchain tarde unos
                    minutos en validar y realizar la transacción. Si esta fue
                    vía onchain, da clic en el número de referencia (el cual
                    podrás encontrar en el comprobante que se muestra en el
                    navegador o en el correo electrónico al cual lo enviaste, en
                    caso de que hayas ingresado uno y dado clic en el botón
                    enviar), que te redireccionará a{' '}
                    <a
                      href="https://mempool.space/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://mempool.space/
                    </a>{' '}
                    con la referencia de tu transacción, y podrás ver en tiempo
                    real el bloque en el que se encuentra y cuándo se procesará.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Col>
        <Col xs={0} sm={0} md={1} lg={2}></Col>
      </Row>

      <Row>
        <Col xs={0} md={1}></Col>
        <Col xs={12} md={10} style={{ textAlign: 'center' }}>
          <h4 className="bold mt-3 mb-5 h2-soporte">
            Si tuviste un inconveniente puedes contactarnos a<br />
            soporte@ditobanx.com
          </h4>
        </Col>
        <Col xs={0} md={1}></Col>
      </Row>
    </Container>
  )
}
