import { types } from '../types/types';

const initialState = {
    tarjetaFound: false,
    walletFound: false,
}

export const tarjetasReducer = (state = initialState, action) => {


    switch (action.type) {
        case types.tarjetaGetTarjeta:
            return {
                ...state,
                ...action.payload,
                tarjetaFound: true,
            }
        case types.tarjetaRedeemTarjeta:
            return {
                ...state,
                ...action.payload,
                walletFound: true,
            }
        case types.tarjetaFoundTarjeta: 
            return {
                ...state,
                tarjetaFound: false,
            }
        case types.tarjetaFoundTarjetaTrue: 
            
            return {
                ...state,
                tarjetaFound: true,
            };
        case types.tarjetaValuesTarjeta:
            return {
                ...state,
                ...action.payload,
            }

        case types.tarjetaClearTarjeta:
            return {
                undefined,
                tarjetaFound: false,
                walletFound: false,
            }

        default:
            return state;
    }
}