import { encryptObject, encrypt } from './crypto';
const baseUrl = process.env.REACT_APP_API_URL;
const { default: axios } = require('axios');

const encryptedKey = encrypt(process.env.REACT_APP_API_KEY);

const fetchSinToken = (endpoint, data, method = 'GET') => {
  const url = `${baseUrl}/${endpoint}`;
  const encryptedData = encryptObject(data);
  const payload = { data: encryptedData };

  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        key: encryptedKey,
      },
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-type': 'application/json',
        key: encryptedKey,
      },
      body: JSON.stringify(payload),
    });
  }
};

const postFetch = async (endpoint, data) => {
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem('token') || '';
  const encryptedToken = encrypt(token);
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      key: encryptedKey,
      Authorization: `Bearer ${encryptedToken}`,
    },
  };

  const response = await axios.post(url, data, config);

  return response.data;
};

const getFetch = async (endpoint) => {
  const url = `${baseUrl}/${endpoint}`
  const token = localStorage.getItem('token') || ''
  const encryptedToken = encrypt(token)
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      key: encryptedKey,
      Authorization: `Bearer ${encryptedToken}`,
    },
  }

  const response = await axios.get(url, config)

  return response.data
}

const fetchConToken = async (endpoint, data, method = 'GET') => {
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem('token') || '';
  const encryptedData = encryptObject(data)
  const encryptedToken = encrypt(token)

  if (method === 'GET') {
    return fetch(url, {
      method,
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${encryptedToken}`,
        key: encryptedKey,
      },
    });
  } else {
    const config = {
      headers: {
        key: encryptedKey,
        Authorization: `Bearer ${encryptedToken}`,
      },
    };

    const response = await axios.post(url, {data: encryptedData}, config);

    return response.data;
  }
};

export { fetchSinToken, fetchConToken, postFetch, getFetch };
