import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const Header = () => {

    const navigate = useNavigate();

  return (
    <Navbar collapseOnSelect expand="lg" bg="black" variant="dark">
        <Container>
            <Navbar.Brand onClick={() => navigate('/')}>
                <img
                    src={require('../assets/LogoDitoCrop02@2x.png')}
                    style={{maxHeight: '50px'}}
                    className="d-inline-block align-top img-fluid"
                    alt="Ditobanx logo"
                />
            </Navbar.Brand>
                <Nav>
                    <Nav.Link eventKey={2} href="https://ditobanx.com/contacto">
                        <i><img 
                        src={require('../assets/DitoBanxIconHelpCrop@2x.png')} 
                        style={{maxHeight: '20px'}}
                        alt="Help icon"
                        /></i> Ayuda
                    </Nav.Link>
                </Nav>
        </Container>
    </Navbar>    
  )
}
